import Swiper from 'swiper'
import { debounce } from 'lodash'

class ProductMain extends HTMLElement {
  constructor () {
    super ()
    const destiniRoot = document.querySelector('#destini-locator')

    if (destiniRoot) {
      const config = { attributes: true, childList: true, subtree: true }
      const callback = (mutations) => {
        mutations.forEach(({ type }) => {
          if (type === 'attributes') {
            this.updateStyleButtonDestini(destiniRoot)
          }
        })
      }
      this.contentObserver = new MutationObserver(callback)
      this.contentObserver.observe(destiniRoot, config)
    }

    this.swiperEl = this.querySelector('.js-product-group-carousel')
    this.swiperWrapperEl = this.querySelector('.js-product-group-wrapper')
    this.swiper = null

    this.resizeSwiper()

    window.addEventListener('resize', debounce(() =>{
      this.resizeSwiper()
    }, 100))

    document.addEventListener('rebuy.ready', (event) => {
      if (!this.classList.contains('rebuy-ready')) {
        this.handleRebuyButton()
      }
    })
  }

  handleRebuyButton () {
    this.classList.add('rebuy-ready')
    const atcButtonEl = this.querySelector('.js-rebuy-atc')
    const atcPriceEl = this.querySelector('.js-rebuy-atc-price')
    const rebuyButton = this.querySelector('.rebuy-button')
    const rebuyPrice = this.querySelector('.rebuy-bundle__actions-price-value')

    if (rebuyButton && atcButtonEl) {
      atcButtonEl.classList.add('is-active')
      atcButtonEl.addEventListener('click', () => {
        rebuyButton.click()
      })
    }

    if (rebuyPrice && atcPriceEl) {
      const productPriceInnerEls = this.querySelectorAll('.js-product-price-inner')
      const rebuySelects = this.querySelectorAll('.rebuy-product-options select')

      const replateContent = () => {
        const content = rebuyPrice.innerHTML
        atcPriceEl.innerHTML = content
        if (productPriceInnerEls) {
          productPriceInnerEls.forEach(el => el.innerHTML = content)
        }
      }
      replateContent()
      if (rebuySelects) {
        rebuySelects.forEach(select => {
          select.addEventListener('change', () => {
            replateContent()
          })
          const optionEls = select.querySelectorAll('option')
          if (optionEls && optionEls.length === 1 && select.style.display !== 'none') {
            select.style.display = 'none'
            const parentEl = select.closest('.rebuy-product-block')
            const titleEl = parentEl?.querySelector('.rebuy-product-title')
            if (titleEl) {
              titleEl.innerHTML = titleEl.innerHTML + ' (' + optionEls[0].innerText + ')'
            }
          }
        })
      }
    }
  }

  resizeSwiper () {
    if (window.innerWidth < 961) {
      this.swiperEl && this.initProductGroupSwiper()
    } else {
      if (this.swiper) {
        this.swiper.destroy(true, true)
        this.afterDestroySwiper()
      }
    }
  }

  afterDestroySwiper () {
    if (this.swiperWrapperEl) {
      this.swiperWrapperEl.style.transform = 'none'
    }
  }

  initProductGroupSwiper () {
    const options = {
      slidesPerView: 'auto',
      spaceBetween: 10,
      draggable: true
    }
    this.swiper = new Swiper(this.swiperEl, options)
  }

  updateStyleButtonDestini(destiniRoot) {
    const style = document.createElement('style')
    style.innerHTML = `
    chakra-scope > .portal-container .chakra-portal {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      border-radius: 100px;
      min-height: 60px;
      background-color: transparent;
      color: #000;
      border: 2px solid #000;
      overflow: hidden;
    }

    chakra-scope > .portal-container .chakra-portal:hover {
      background-color: transparent;
      color: #000;
      border: 2px solid #000;
    }

    chakra-scope > .portal-container .chakra-portal:before,
    chakra-scope > .portal-container .chakra-portal:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    chakra-scope > .portal-container .chakra-portal:before {
      width: 130%;
      left: -15%;
      background: #FFF;
      z-index: 2;
      transform: skew(30deg);
      transition: transform 500ms cubic-bezier(0.33, 0, 0, 1);
      transition: transform var(--duration-normal) var(--easing);
    }

    chakra-scope > .portal-container .chakra-portal:after {
      background: #F0F0F0;
      z-index: 1;
    }

    chakra-scope > .portal-container .chakra-portal:hover:before {
      transform: translate3d(100%, 0, 0);
    }

    chakra-scope > .portal-container .chakra-button {
      position: relative;
      z-index: 3;
      width: 100%;
      min-height: 56px;
      background-color: transparent;
    }

    .destini-css-1u2cvaz,
    .destini-css-hdd9l7 {
      z-index: 99999;
    }

    @media (min-width: 960px) {
      chakra-scope > .portal-container .chakra-portal,
      chakra-scope > .portal-container .chakra-button {
        min-height: 66px;
      }
    }
    `
    destiniRoot.shadowRoot.appendChild(style)
  }
}

window.customElements.define('product-main', ProductMain)