import { setItemWithExpiration, getItemWithExpiration, getUrlParam, setUrlParam, removeUrlParam, graphQLRequest, range, removeItemFromStringArr, addItemToStringArrIfNotExists, capitalize } from "../utils"

const RATE_LIMIT = 50
const BLOG_COOKIE = 'blog_filter'
const PAGE_PARAM = 'page'
const SORT_BY_PARAM = 'sort_by'
const FILTER_PARAM = 'filter.b.tags'
const ACTIVE_CLASS = 'is-active'
const FILTER_ACTIVE_CLASS = 'filters-active'
const HIDDEN_CLASS = 'hidden'

const articleCard = (article) => {
  if (article.image == null) article.image = {
    url: ''
  }
  return `
  <div class="article-item hover" data-image="true">
    <div class="mb-[12px] md:mb-[18px] rounded-[8px] overflow-hidden">
      <a href="${article.onlineStoreUrl}" class="relative block h-0 pb-[100%] aspect-square transition-all md:hover:scale-[1.05]" aria-label="Link to ${article.title}" tabindex="-1">
        <div class="image w-full transition-all !absolute top-0 left-0 h-full [&_img]:w-full [&_img]:h-full [&_img]:!object-cover loaded">
          <img alt="${article.title}" class="image__img" width="1912" height="1912"
            src="${article.image.url}&amp;width=320"
            srcset="${article.image.url}&amp;width=300 300w,
              ${article.image.url}&amp;width=100 100w,
              ${article.image.url}&amp;width=150 150w,
              ${article.image.url}&amp;width=200 200w,
              ${article.image.url}&amp;width=240 240w,
              ${article.image.url}&amp;width=280 248w,
              ${article.image.url}&amp;width=300 300w,
              ${article.image.url}&amp;width=360 360w,
              ${article.image.url}&amp;width=400 400w,
              ${article.image.url}&amp;width=450 450w,
              ${article.image.url}&amp;width=500 500w,
              ${article.image.url}&amp;width=550 550w,
              ${article.image.url}&amp;width=600 600w,
              ${article.image.url}&amp;width=650 650w,
              ${article.image.url}&amp;width=700 700w,
              ${article.image.url}&amp;width=750 750w,
              ${article.image.url}&amp;width=800 800w,
              ${article.image.url}&amp;width=850 850w,
              ${article.image.url}&amp;width=900 900w,
              ${article.image.url}&amp;width=950 950w,
              ${article.image.url}&amp;width=1000 1000w,
              ${article.image.url}&amp;width=1100 1100w,
              ${article.image.url}&amp;width=1300 1300w,
              ${article.image.url}&amp;width=1400 1400w,
              ${article.image.url}&amp;width=1600 1600w,
              ${article.image.url}&amp;width=1800 1800w,
              ${article.image.url}&amp;width=2000 2000w,"
            sizes="(min-width: 720px) calc(calc(50vw - calc(2 * clamp(18px, 3.3vw, 3.3vw)))), calc(100vw - calc(2 * 3.3vw))">
        </div>
      </a>
    </div>
    <div class="relative block">
      <h3 class="editorial-h3">
        <a href="${article.onlineStoreUrl}" class="text-black link-hover-custom">
          ${article.title}
        </a>
      </h3>
    </div>
  </div>
  `
}

const closeSmallIcon = `
<span class="icon icon-new icon-close-small">
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.717 1.975 3.347.605.604 3.346l1.37 1.37 2.742-2.74zm14.566 20.05 1.37 1.37 2.742-2.741-1.37-1.37-2.742 2.74zm2.742-17.308 1.37-1.37L20.654.604l-1.37 1.37 2.74 2.742zM1.975 19.283l-1.37 1.37 2.741 2.742 1.37-1.37-2.74-2.742zm0-14.566 8.654 8.654 2.742-2.742-8.654-8.654-2.742 2.742zm11.396 8.654 8.654-8.654-2.742-2.742-8.654 8.654 2.742 2.742zm-2.742-2.742-8.654 8.654 2.742 2.741 8.654-8.653-2.742-2.742zm0 2.742 8.654 8.653 2.742-2.74-8.654-8.655-2.742 2.742z" fill="currentColor"></path></svg>
</span>
`

class MainBlogFilter extends HTMLElement {
  constructor() {
    super()
    this.rootEl = this.querySelector('.js-main-blog-filter')

    // Get data from URL
    this.pathSplit = window.location.pathname.split('/tagged/')
    this.currentBlog = this.pathSplit[0].includes('/blogs') ? this.pathSplit[0].split('/blogs/')[1] : null
    this.currentTag = this.pathSplit[1] ? this.pathSplit[1] : null
    this.currentPage = parseInt(getUrlParam(PAGE_PARAM)) || 1
    this.currentSort = getUrlParam(SORT_BY_PARAM) || 'date-descending'
    this.currentFilter = getUrlParam(FILTER_PARAM) || null

    // Get blog cache in localStorage
    this.blogCacheKey = `${BLOG_COOKIE}:${this.currentBlog}-${this.currentTag}`
    this.blogCacheData = getItemWithExpiration(this.blogCacheKey) ? JSON.parse(getItemWithExpiration(this.blogCacheKey)) : null
    this.articles = this.blogCacheData || []
    this.renderArticles = this.articles

    // Get texts
    this.noResultsText = this.rootEl.dataset.noResultsText
    this.oneResultText = this.rootEl.dataset.oneResultText
    this.manyResultsText = this.rootEl.dataset.manyResultsText
    this.zeroSelectedText = this.rootEl.dataset.zeroSelectedText
    this.paginationViewingText = this.rootEl.dataset.paginationViewing

    // Get options
    this.itemsPerPage = this.rootEl.dataset.collectionItemsPerPage

    // Get elements
    this.loadingEl = this.rootEl.querySelector('[data-loading]')
    this.blogListEl = this.rootEl.querySelector('[data-blog-list]')
    this.blogEmptyEl = this.rootEl.querySelector('[data-blog-empty]')
    this.paginationEl = this.rootEl.querySelector('[data-blog-pagination]')
    this.paginationItemCountEl = this.rootEl.querySelector('[data-blog-pagination-item-count]')
    this.paginationPrevEl = this.rootEl.querySelector('[data-blog-pagination-prev]')
    this.paginationPrevLinkEl = this.rootEl.querySelector('[data-blog-pagination-prev] a')
    this.paginationNextEl = this.rootEl.querySelector('[data-blog-pagination-next]')
    this.paginationNextLinkEl = this.rootEl.querySelector('[data-blog-pagination-next] a')
    this.resultsCountEls = this.rootEl.querySelectorAll('[data-results-count]')
    this.filterBarEl = this.rootEl.querySelector('[data-filter-bar]')
    this.activeFilterEls = this.rootEl.querySelectorAll('[data-blog-active-filters]')
    this.filterCountEls = this.rootEl.querySelectorAll('[data-filter-count]')
    this.filterItemInputEls = this.rootEl.querySelectorAll('[data-blog-filter-item-input]')
    this.filterGroupEls = this.rootEl.querySelectorAll('[data-filter-group]')
    this.filterGroupResetEls = this.rootEl.querySelectorAll('[data-blog-filter-group-reset]')
    this.filterToggleEl = this.rootEl.querySelector('[data-dropdown-toggle="filter-bar-filters"]')
    this.sortItemInputEls = this.rootEl.querySelectorAll('[data-blog-sort-item-input]')
    this.sortLabelEl = this.rootEl.querySelector('[data-sort-label]')
    this.clearAllFiltersEls = this.rootEl.querySelectorAll('[data-blog-clear-all-filters]')

    // If article cache exists, render it
    if (this.articles.length) {
      this.render(true)
    } else {
      // If not, fetch articles, save to cache and render
      this.fetchAllArticles().then(() => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 1); // Save to cache, valid for 1 day
        setItemWithExpiration(this.blogCacheKey, JSON.stringify(this.articles), expirationDate)
        this.render(true)
      })
    }

    // Add event listeners
    this.filterItemInputEls.forEach(el => el.addEventListener('change', e => this.handleFilter(e.target)))
    this.filterGroupResetEls.forEach(el => el.addEventListener('click', () => this.handleGroupReset(el)))
    this.sortItemInputEls.forEach(el => el.addEventListener('change', e => this.handleSort(e.target)))
    this.clearAllFiltersEls.forEach(el => el.addEventListener('click', (e) => this.handleClearAllFilters(e)))
  }

  render(isFirstTime) {
    // If not first time render (e.g. when filter/sort is changed), scroll to top
    if (!isFirstTime) this.scrollToTop()

    // Handle show filter bar if first time render
    if (isFirstTime) this.showDropdownBar()

    // Every time render, reset renderArticles
    this.renderArticles = [...this.articles]
    this.renderArticles = this.renderArticles.filter(item => !item.tags.includes('_hidden_categories'))

    // Handle filter
    if (this.currentFilter) {
      const tags = this.currentFilter && this.currentFilter.includes(',') ? this.currentFilter.split(',') : [this.currentFilter]
      this.renderArticles = this.articles.filter(article => tags.every(tag => [...article.tags.map(str => str.toLowerCase())].includes(tag)))
    }

    // Handle sort
    switch (this.currentSort) {
      case 'title-ascending':
        this.renderArticles = this.renderArticles.sort((a, b) => a.title.localeCompare(b.title))
        break
      case 'title-descending':
        this.renderArticles = this.renderArticles.sort((a, b) => b.title.localeCompare(a.title))
        break
      case 'date-ascending':
        this.renderArticles = this.renderArticles.sort((a, b) => new Date(a.publishedAt) - new Date(b.publishedAt))
        break
      case 'date-descending':
        this.renderArticles = this.renderArticles.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt))
        break
    }

    // Slice renderArticles array into new array with articles for current page
    const articles = this.paginateArray([...this.renderArticles])

    this.showArticles(articles)
    this.updateUI()
    this.showPagination()
  }

  async fetchAllArticles() {
    const blogQuery = (endCursor) => /* GraphQL */ `
      query {
        blog(handle: "${this.currentBlog}") {
          articles(first: ${RATE_LIMIT}${endCursor ? `, after: "${endCursor}"` : ''}, ${this.currentTag ? `query: "tag:${this.currentTag}"` : ''}) {
            edges {
              cursor
              node {
                id
                onlineStoreUrl
                handle
                title
                tags
                publishedAt
                image {
                  altText
                  id
                  url
                }
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      }
    `
    let hasNextPage = true
    let endCursor = ''

    // Loop until there are no more pages
    while(hasNextPage) {
      const response = await graphQLRequest(blogQuery(endCursor))
      if (!response.data) return
      const { articles : { edges, pageInfo } } = response.data.blog
      this.articles = [...this.articles, ...edges.map(({ node }) => node)]
      hasNextPage = pageInfo.hasNextPage
      endCursor = pageInfo.endCursor
    }
  }

  showArticles(articles) {
    // Hide loading
    this.hideLoading()

    // Clear old articles
    this.blogListEl.innerHTML = ''
    this.blogEmptyEl.classList.add(HIDDEN_CLASS)

    // Show empty state if no articles
    if (!articles.length) return this.blogEmptyEl.classList.remove(HIDDEN_CLASS)

    // Render new articles
    articles.forEach(article => this.blogListEl.insertAdjacentHTML('beforeend', articleCard(article)))
  }

  updateUI() {
    // Get all unique tags from articles
    const allTags = this.extractUniqueTags(this.articles)

    // Get all unique tags from renderArticles
    const filterAllTags = this.extractUniqueTags(this.renderArticles)

    // Split filter tags from URL into array
    const filterTags = this.currentFilter ? this.currentFilter.includes(',') ? this.currentFilter.split(',') : [this.currentFilter] : []

    // Results count
    switch(this.renderArticles.length) {
      case 0:
        this.resultsCountEls.forEach(el => el.innerHTML = this.noResultsText)
        break
      case 1:
        this.resultsCountEls.forEach(el => el.innerHTML = this.oneResultText.replace('{{ value }}', this.renderArticles.length))
        break
      default:
        this.resultsCountEls.forEach(el => el.innerHTML = this.manyResultsText.replace('{{ value }}', this.renderArticles.length))
        break
    }

    // Loop through all filter checkbox and check if they are in filterTags array
    this.filterItemInputEls.forEach(el => {
      // Reset
      el.closest('[data-blog-filter]').classList.remove(HIDDEN_CLASS)
      el.disabled = false

      // If tag is not in allTags, hide it
      if (!allTags.includes(el.value)) el.closest('[data-blog-filter]').classList.add(HIDDEN_CLASS)

      // If tag is not in filterAllTags, disable it
      if (!filterAllTags.includes(el.value)) el.disabled = true

      // If tag is in filterTags, check it
      if (filterTags.includes(el.value)) {
        el.checked = true
      } else {
        el.checked = false
      }
    })

    // Update all filter group count, show/hide "Clear" button in each group
    this.filterGroupEls.forEach(el => {
      const filterEls = el.querySelectorAll('[data-blog-filter]')
      const isAllHidden = Array.from(filterEls).every(element => element.classList.contains(HIDDEN_CLASS))

      // Hide group if all filters are hidden
      if (isAllHidden) return el.classList.add(HIDDEN_CLASS)

      const filterItemsChecked = el.querySelectorAll('[data-blog-filter-item-input]:checked')
      const filterItemsCheckedLength = filterItemsChecked.length
      const groupActiveCountEl = el.querySelector('[data-group-active-count]')
      const groupActiveValuesEl = el.querySelector('[data-group-active-values]')
      const groupResetEl = el.querySelector('[data-blog-filter-group-reset]')

      if (filterItemsCheckedLength === 0) {
        if (groupActiveCountEl) groupActiveCountEl.innerHTML = ''
        if (groupActiveValuesEl) groupActiveValuesEl.innerHTML = this.zeroSelectedText
        if (groupResetEl) groupResetEl.classList.remove('active')
      } else {
        if (groupActiveCountEl) groupActiveCountEl.innerHTML = `(${filterItemsCheckedLength})`
        if (groupActiveValuesEl) groupActiveValuesEl.innerHTML = [...filterItemsChecked].map(el => capitalize(el.value)).join(', ')
        if (groupResetEl) groupResetEl.classList.add('active')
      }
    })

    // Update all filter count (mobile)
    this.filterCountEls.forEach(el => {
      el.style.display = filterTags.length ? 'inline' : 'none'
      el.innerHTML = filterTags.length
    })

    // Show/hide filter bar
    this.showFilterItems(filterTags)
    if (filterTags.length) this.filterBarEl && this.filterBarEl.classList.add(FILTER_ACTIVE_CLASS)
    else this.filterBarEl && this.filterBarEl.classList.remove(FILTER_ACTIVE_CLASS)

    // Update all sort radio
    let sortLabel = ''
    this.sortItemInputEls.forEach(el => {
      if (el.value === this.currentSort) {
        sortLabel = el.dataset.label
        el.checked = true
      } else {
        el.checked = false
      }
    })

    // Update sort label
    if (this.sortLabelEl) {
      this.sortLabelEl.innerHTML = sortLabel
    }
  }

  showDropdownBar () {
    const filterTags = this.currentFilter ? this.currentFilter.includes(',') ? this.currentFilter.split(',') : [this.currentFilter] : []
    if (filterTags.length > 0) this.filterToggleEl.click()
  }

  scrollToTop() {
    window.scrollTo({
      top: this.rootEl.getBoundingClientRect().top + window.scrollY,
      behavior: 'smooth'
    })
  }

  extractUniqueTags = (arr) => [...new Set(arr.flatMap(obj => obj.tags || []))]

  handleSort = (e) => {
    // Reset
    this.currentPage = 1
    this.currentSort = e.value

    // Update params in URL
    removeUrlParam(PAGE_PARAM)
    setUrlParam(SORT_BY_PARAM, this.currentSort)

    // Re-render
    this.render()
  }

  handleFilter = (e) => {
    // Get new filter value
    const newFilter = e.checked
      ? this.currentFilter ? addItemToStringArrIfNotExists(this.currentFilter, e.value) : e.value
      : this.currentFilter ? removeItemFromStringArr(this.currentFilter, e.value) : null

    // Reset
    this.currentPage = 1
    this.currentFilter = newFilter

    // Update params in URL
    removeUrlParam(PAGE_PARAM)
    if (!newFilter) removeUrlParam(FILTER_PARAM)
    else setUrlParam(FILTER_PARAM, newFilter)

    // Re-render
    this.render()
  }

  handleGroupReset(el) {
    const group = el.closest('[data-filter-group]')
    const filterItems = group.querySelectorAll('[data-blog-filter-item-input]:checked')
    let newFilter = this.currentFilter

    // Remove filter value, uncheck all checkboxes of this group
    filterItems.forEach(el => {
      newFilter = removeItemFromStringArr(newFilter, el.value)
      el.checked = false
    })

    // Reset
    this.currentPage = 1
    this.currentFilter = newFilter

    // Update params in URL
    removeUrlParam(PAGE_PARAM)
    if (!newFilter) removeUrlParam(FILTER_PARAM)
    else setUrlParam(FILTER_PARAM, newFilter)

    // Re-render
    this.render()
  }

  handleClearAllFilters(e) {
    e.preventDefault()

    // Reset
    this.currentPage = 1
    this.currentFilter = null

    // Remove params from URL
    removeUrlParam(PAGE_PARAM)
    removeUrlParam(FILTER_PARAM)

    // Re-render
    this.render()
  }

  showFilterItems(tags) {
    this.activeFilterEls.forEach(el => {
      // Reset
      el.querySelectorAll('[data-blog-remove-filter]').forEach(el => el.remove())
      // Loop through tags and render them
      tags.forEach(tag => {
        const a = document.createElement('a')
        a.classList.add('active-filters__active-filter', 'label-large', 'no-transition', 'cursor-pointer')
        a.setAttribute('data-blog-remove-filter', '')
        // Add remove filter event listener
        a.addEventListener('click', () => this.handleFilter({ checked: false, value: tag }))
        a.innerHTML = capitalize(tag) + closeSmallIcon
        // Insert before "Clear all" button
        el.insertBefore(a, el.querySelector('[data-blog-clear-all-filters]'))
      })
    })
  }

  paginateArray(arr) {
    const startIndex = (this.currentPage - 1) * parseInt(this.itemsPerPage)
    const endIndex = startIndex + parseInt(this.itemsPerPage)
    const pageItems = arr.slice(startIndex, endIndex)
    return pageItems
  }

  getPaginationRange() {
    const dots = '...'
    const siblingCount = 1
    const totalPageCount = Math.ceil(this.renderArticles.length / this.itemsPerPage)
    const totalPageNumbers = siblingCount + 5

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount)
    }

    const leftSiblingIndex = Math.max(this.currentPage - siblingCount, 1)
    const rightSiblingIndex = Math.min(
      this.currentPage + siblingCount,
      totalPageCount
    )

    const shouldShowLeftDots = leftSiblingIndex > 2
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

    const firstPageIndex = 1
    const lastPageIndex = totalPageCount

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount
      let leftRange = range(1, leftItemCount)

      return [...leftRange, dots, totalPageCount]
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, dots, ...rightRange]
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex)
      return [firstPageIndex, dots, ...middleRange, dots, lastPageIndex]
    }
  }

  showPagination() {
    // Reset
    this.rootEl.querySelectorAll('[data-blog-pagination-item]').forEach(el => el.remove())
    this.paginationPrevEl.classList.remove(ACTIVE_CLASS)
    this.paginationNextEl.classList.remove(ACTIVE_CLASS)
    this.paginationItemCountEl.classList.add(HIDDEN_CLASS)

    // Dom Section
    const sectionBlogFilter = '#main-blog-filter'

    // Get an array of page numbers (e.g. [1, 2, '...', 5, 6])
    const paginationRange = this.getPaginationRange()
    if (!paginationRange) return this.hidePagination()

    // Show total number of results if only 1 page
    if (paginationRange.length === 1) {
      if (!this.paginationItemCountEl) return this.hidePagination()
      const resultText = this.paginationViewingText.replace('{{ total }}', this.renderArticles.length).replace('{{ of }}', `1-${this.renderArticles.length}`) + ' posts'
      this.paginationItemCountEl.classList.remove(HIDDEN_CLASS)
      this.paginationItemCountEl.firstElementChild.innerText = resultText
      return this.hidePagination()
    }

    // If > 1 page, show pagination
    this.paginationEl.classList.remove(HIDDEN_CLASS)

    // Show/hide next/prev button
    if (this.currentPage > 1) this.paginationPrevEl.classList.add(ACTIVE_CLASS)
    if (this.currentPage < paginationRange[paginationRange.length - 1]) this.paginationNextEl.classList.add(ACTIVE_CLASS)

    // Render pagination elements
    paginationRange.forEach(page => {
      const searchParams = new URLSearchParams(window.location.search)
      const li = document.createElement('li')
      li.classList.add('px-[6px]')
      li.setAttribute('data-blog-pagination-item', '')

      if (page === this.currentPage) {
        // Update prev link
        searchParams.set(PAGE_PARAM, page - 1)
        this.paginationPrevLinkEl.href = window.location.pathname + '?' + searchParams.toString() + sectionBlogFilter
        // Update next link
        searchParams.set(PAGE_PARAM, page + 1)
        this.paginationNextLinkEl.href = window.location.pathname + '?' + searchParams.toString() + sectionBlogFilter
        // Update active link
        li.classList.add('active')
        li.innerHTML = `<span class="underline underline-offset-[2px] text-black opacity-100">${page}</span>`
      } else if (typeof page === 'string') {
        // Update "..." button
        li.innerHTML = `<span class="text-black opacity-50 hover:opacity-100">${page}</span>`
      } else {
        // Update page link
        searchParams.set(PAGE_PARAM, page)
        li.innerHTML = `<a href="${window.location.pathname + '?' + searchParams.toString() + sectionBlogFilter}" class="text-black opacity-50 hover:opacity-100" aria-label="Page ${page} of products">${page}</a>`
      }

      this.paginationEl.querySelector('ul').insertBefore(li, this.paginationNextEl)
    })

    // Re-add page transition function
    if (typeof window.pageTransition === 'function') window.pageTransition()
  }

  hidePagination = () => this.paginationEl.classList.add(HIDDEN_CLASS)

  showLoading = () => this.loadingEl.classList.add(ACTIVE_CLASS)

  hideLoading = () => this.loadingEl.classList.remove(ACTIVE_CLASS)
}

window.customElements.define('main-blog-filter', MainBlogFilter)