class VideoHero extends HTMLElement {
  constructor () {
    super ()

    const video = this.querySelector('video')
    const videoButton = this.querySelector('.js-button-video')

    videoButton.addEventListener('click', function (e) {
      e.preventDefault()

      if (this.classList.contains('played')) {
        video.pause()
        this.classList.remove("played")
      } else {
        video.play()
        this.classList.add("played")
      }
    })
  }
}

window.customElements.define('video-hero', VideoHero)