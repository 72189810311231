class StoreLocator extends HTMLElement {
  constructor () {
    super ()
    const destiniRoot = document.querySelector('#destini-locator')

    if (destiniRoot) {
      this.updateStyleButtonDestini(destiniRoot)
    }
  }

  updateStyleButtonDestini(destiniRoot) {
    const style = document.createElement('style')
    style.innerHTML = `
      #locator-template-container > div > div > div > div {
        max-width: 1280px !important;
      }

      .destini-css-19urke7 {
        padding: 0 20px;
      }

      .destini-css-1eexr6f {
        margin: 0;
      }

      .destini-css-ly3ps3 {
        padding: 0;
      }

      .chakra-input__right-element {
        height: 37px;
        top: 50%;
        right: -7px;
        transform: translateY(-50%);
      }

      @media (min-width: 375px) {
        .chakra-input__right-element {
          right: 18px;
        }
      }

      .chakra-button[aria-label="Select Specific Products"],
      .chakra-button[aria-label="Find Products"] {
        font-size: 14px;
        line-height: 1.25;
        font-weight: bold;
      }

      .chakra-button[aria-label="Select Specific Products"],
      .chakra-button[aria-label="Find Products"] {
        position: relative;
        width: 100%;
        height: 40px;
        padding-left: 32px;
        padding-right: 32px;
        background: transparent;
        border-radius: 34px;
        z-index: 1;
        overflow: hidden;
      }


      @media (min-width: 768px) {
        .chakra-button[aria-label="Select Specific Products"],
        .chakra-button[aria-label="Find Products"] {
          height: 50px;
        }
      }

      .chakra-button[aria-label="Select Specific Products"]::before,
      .chakra-button[aria-label="Find Products"]::before {
        content: '';
        position: absolute;
        top: 0;
        left: -15%;
        width: 130%;
        height: 100%;
        background-color: #000000;
        transform: skew(30deg);
        z-index: -1;
        transition: transform 0.5s cubic-bezier(.33, 0, 0, 1);
      }

      .chakra-button[aria-label="Select Specific Products"]::after,
      .chakra-button[aria-label="Find Products"]::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
        border-radius: 100px;
        background-color: #353535;
      }

      .chakra-button[aria-label="Select Specific Products"]:hover::before,
      .chakra-button[aria-label="Find Products"]:hover::before {
        transform: translate3d(100%,0,0);
      }

      .destini-css-xi1vyr,
      .destini-css-1kb2g8x,
      .destini-css-1ngv3x {
        font-size: 14px;
        line-height: 1.5;
        font-weight: 500;
      }

      .destini-css-1sqfii2,
      .destini-css-1sl9a05,
      .destini-css-4i874u {
        font-size: 12px;
        line-height: 1.2;
        font-weight: 600;
      }

      .chakra-accordion__item .chakra-collapse .chakra-button,
      .chakra-accordion__item .chakra-collapse .chakra-button .chakra-text,
      .destini-css-1c6ut1a,
      .destini-css-lbbipf,
      .destini-css-14xyvn9,
      div[role="region"] > div:first-child > div > button {
        font-size: 14px;
        line-height: 1.4;
        font-weight: 500;
      }

      .chakra-accordion__item .chakra-collapse .chakra-button .chakra-text:first-child::before {
        content: none;
      }

      .chakra-accordion__button .chakra-text:first-child,
      .chakra-accordion__item .chakra-button .chakra-text,
      .destini-css-s3971,
      .destini-css-1xgomcu,
      .destini-css-1lfxfv5,
      .destini-css-17hodym {
        font-size: 16px;
        line-height: 1.4;
        font-weight: 700;
      }

      .destini-css-z9bkde button {
        font-size: 16px;
        line-height: 1.25;
        font-weight: 600;
      }

      .destini-css-17hodym {
        padding-bottom: 22px;
      }

      .chakra-accordion__item .chakra-button {
        padding: 12px 0;
      }

      .chakra-accordion__item .chakra-button .chakra-text,
      .chakra-accordion__item .chakra-collapse .chakra-button,
      .chakra-accordion__button .chakra-text:first-child {
        position: relative;
        border: none;
        text-decoration: none;
      }

      .chakra-accordion__item .chakra-collapse .chakra-button {
        width: fit-content;
        height: auto;
        margin-bottom: 5px;
        padding: 0;
      }

      .chakra-accordion__item .chakra-button .chakra-text:first-child::before,
      .chakra-accordion__item .chakra-collapse .chakra-button::before,
      .chakra-accordion__button .chakra-text:first-child::before {
        content: "";
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: 1px;
        background: currentColor;
        pointer-events: none;
        transform-origin: 100% 50%;
        transform: scale3d(0,1,1);
        transition: transform .3s cubic-bezier(.33,0,0,1);
      }

      .chakra-accordion__item .chakra-button:hover .chakra-text:first-child::before,
      .chakra-accordion__item .chakra-collapse .chakra-button:hover::before,
      .chakra-accordion__button:hover .chakra-text:first-child::before {
        transform-origin: 0% 50%;
        transform: scaleZ(1);
      }

      .chakra-accordion__item .chakra-button:hover .chakra-text::before {
        transform: scale3d(0,1,1);
        transition-delay: 0s;
      }

      .destini-css-1ym8wf3:focus, .destini-css-1ym8wf3[data-focus],
      .destini-css-1ym8wf3:hover, .destini-css-1ym8wf3[data-hover],
      .destini-css-8ahe9f:hover, .destini-css-8ahe9f[data-hover],
      .destini-css-8ahe9f:focus, .destini-css-8ahe9f[data-focus],
      .destini-css-8ahe9f {
        padding: 0;
        border: none;
      }

      .chakra-accordion__button,
      .destini-css-tvqnva,
      .destini-css-chqxst {
        padding: 0;
      }

      .chakra-accordion__item .chakra-button:hover,
      .chakra-accordion__item .chakra-button:active,
      .chakra-accordion__button:hover {
        background: transparent;
      }

      .destini-css-chqxst {
        align-items: start;
        margin-bottom: 36px !important;
      }

      .chakra-accordion__item,
      .destini-css-17mg6aq {
        border-color: #F0F0F0;
      }

      .destini-css-18jqfyr .destini-css-1qq679y {
        left: 20px !important;
      }

      .destini-css-1u86yuw {
        margin: 41px 0 0;
      }

      .destini-css-1c6ut1a {
        margin: 0 0 15px;
        color: #000000;
      }

      .destini-css-k008qs {
        align-items: center;
      }

      .destini-css-tbrn32 {
        width: 36px;
        height: 36px;
        margin: 0;
        border: 2px solid #090909;
        border-radius: 50%;
      }

      .destini-css-tbrn32[aria-label="previous page"] svg,
      .destini-css-tbrn32[aria-label="next page"] svg,
      .destini-css-tbrn32[aria-label="first page"],
      .destini-css-tbrn32[aria-label="last page"] {
        display: none;
      }

      .destini-css-tbrn32[aria-label="next page"],
      .destini-css-tbrn32[aria-label="previous page"] {
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDYgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01IDguNTI1MDVMMS44IDQuODM3MjdMNSAxLjM1MDAzIiBzdHJva2U9IiMwOTA5MDkiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K);
      }

      .destini-css-tbrn32[aria-label="next page"]:hover,
      .destini-css-tbrn32[aria-label="previous page"]:hover {
        background-color: transparent;
        box-shadow: 0px 0px 15px 0px #00000040;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDYgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01IDguNTI1MDVMMS44IDQuODM3MjdMNSAxLjM1MDAzIiBzdHJva2U9IiMwOTA5MDkiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K);
      }

      .destini-css-tbrn32[aria-label="next page"] {
        transform: rotate(180deg);
      }

      .destini-css-z9bkde {
        margin: 0 48px;
      }

      .destini-css-z9bkde button {
        width: auto;
        min-width: auto;
        height: auto;
        margin: 0 12px;
        border: none;
        color: #090909;
        opacity: 0.5;
      }

      .destini-css-z9bkde button[aria-current="page"] {
        opacity: 1;
        text-decoration: underline;
      }

      .chakra-button[aria-label="Find Products"] {
        border-radius: 50px;
      }

      .destini-css-1n0zzxl {
        margin: 22px 0 27px !important;
      }

      .destini-css-9ayuzj {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 30px;
        row-gap: 33px;
      }

      .destini-css-1xhj18k {
        column-gap: 3px;
      }

      .destini-css-qdtoet {
        width: auto;
      }

      .destini-css-e1g2t3 {
        justify-content: start;
        width: auto;
        padding: 0;
      }

      .destini-css-1sugwtq svg,
      .destini-css-e1g2t3 svg {
        border: 1px solid #000000 !important;
        border-radius: 3px !important;
      }

      .destini-css-y2ksjf {
        width: auto;
      }

      .destini-css-a2c7so,
      .destini-css-1rmd75r {
        width: 100%;
        height: auto;
        margin-bottom: 14px;
        aspect-ratio: 1;
      }

      .destini-css-15vv5sq,
      .destini-css-7p6n6e {
        max-width: 100%;
        max-height: none;
        border-radius: 5px
      }

      .destini-css-1dv2ycm,
      .destini-css-10woty6 {
        padding: 0;
        font-size: 18px;
        line-height: 1.25;
        font-weight: 700;
      }

      .destini-css-kocz2w {
        margin-bottom: 107px;
      }

      .destini-css-1dv2ycm {
        display: none;
      }

      .destini-css-1vsmthr {
        padding-bottom: 0;
      }

      .destini-css-1xjr7ix {
        padding: 0;
        background: transparent;
      }

      .destini-css-13z0bvs svg,
      .destini-css-cqcnk0,
      .chakra-button[aria-label="Find Products"] svg {
        display: none;
      }

      .destini-css-1qmtwfj,
      .destini-css-1sl9a05,
      .destini-css-1en0sct,
      .destini-css-17pm5ep {
        width: 100%;
      }

      .destini-css-17pm5ep {
        margin-bottom: 14px;
      }

      .destini-css-xi1vyr {
        max-width: none;
      }

      .destini-css-1kb2g8x {
        padding: 16px 22px 5px;
        border-color: #F0F0F0;
        border-radius: 10px;
      }

      .destini-css-14j0341 {
        left: 22px;
      }

      .destini-css-13z0bvs,
      .destini-css-kjafn5 {
        width: 27px;
        height: 23px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyNyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIzLjcyNDEgMTMuNTM1OEwyNS44NDc3IDQuMTkyMDZDMjYuMDAxMSAzLjUxNzQzIDI1LjQ4ODMgMi44NzUgMjQuNzk2NCAyLjg3NUg3LjE1MTkyTDYuNzQwMTcgMC44NjIwNTFDNi42Mzc1NyAwLjM2MDMxOCA2LjE5NjA3IDAgNS42ODM5MiAwSDEuMDc4MTJDMC40ODI2ODYgMCAwIDAuNDgyNjg2IDAgMS4wNzgxMlYxLjc5Njg4QzAgMi4zOTIzMSAwLjQ4MjY4NiAyLjg3NSAxLjA3ODEyIDIuODc1SDQuMjE3NEw3LjM3MzA3IDE4LjMwMjdDNi42MTgxMSAxOC43MzY5IDYuMTA5MzggMTkuNTUxIDYuMTA5MzggMjAuNDg0NEM2LjEwOTM4IDIxLjg3MzcgNy4yMzU2NiAyMyA4LjYyNSAyM0MxMC4wMTQzIDIzIDExLjE0MDYgMjEuODczNyAxMS4xNDA2IDIwLjQ4NDRDMTEuMTQwNiAxOS43ODAzIDEwLjg1MSAxOS4xNDQxIDEwLjM4NDkgMTguNjg3NUgxOS44MDI2QzE5LjMzNjUgMTkuMTQ0MSAxOS4wNDY5IDE5Ljc4MDMgMTkuMDQ2OSAyMC40ODQ0QzE5LjA0NjkgMjEuODczNyAyMC4xNzMyIDIzIDIxLjU2MjUgMjNDMjIuOTUxOCAyMyAyNC4wNzgxIDIxLjg3MzcgMjQuMDc4MSAyMC40ODQ0QzI0LjA3ODEgMTkuNDg4NCAyMy40OTkyIDE4LjYyNzcgMjIuNjU5NSAxOC4yMjAxTDIyLjkwNzQgMTcuMTI5NkMyMy4wNjA3IDE2LjQ1NDkgMjIuNTQ3OSAxNS44MTI1IDIxLjg1NjEgMTUuODEyNUg5Ljc5ODIyTDkuNTA0MjEgMTQuMzc1SDIyLjY3MjhDMjMuMTc2MiAxNC4zNzUgMjMuNjEyNiAxNC4wMjY3IDIzLjcyNDEgMTMuNTM1OFoiIGZpbGw9IiMyMzFGMjAiLz4KPC9zdmc+Cg==);
        background-size: contain;
        background-repeat: no-repeat;
      }

      .destini-css-79elbk {
        margin-right: 35px;
      }

      .destini-css-13z0bvs {
        margin-right: 0;
      }

      .destini-css-zfvr97,
      .destini-css-1iatloo {
        top: -4px;
        width: 19px;
        height: 19px;
        background-color: #D9D9D9;
        color: #000000;
        font-size: 12px;
        line-height: 15px;
        font-weight: 600;
      }

      .destini-css-zfvr97 {
        right: -10px;
      }

      .chakra-button[aria-label="Find Products"] {
        height: 37px;
        width: 120px;
        min-width: 120px;
        padding-right: 10px;
        padding-left: 10px;
      }

      @media (min-width: 375px) {
        .chakra-button[aria-label="Find Products"] {
          width: 150px;
          min-width: 150px;
          padding-right: 32px;
          padding-left: 32px;
        }
      }

      @media (min-width: 768px) {
        .chakra-button[aria-label="Find Products"] {
          height: 50px;
        }
      }

      .destini-css-1040cqu {
        display: flex;
      }

      .destini-css-1khn86w {
        width: 306px;
        padding: 0;
      }

      .destini-css-1khn86w + div {
        width: calc(100% - 306px);
        padding-left: 60px;
      }

      .destini-css-10woty6::before,
      .destini-css-tvqnva::before {
        display: block;
        content: 'Select one or more products to begin.';
        margin-bottom: 14px;
      }

      .destini-css-10woty6::before {
        margin-bottom: 7px;
      }

      .destini-css-182reb7 {
        width: 305px;
        min-width: 305px;
        height: 79px;
        margin-bottom: 0;
      }

      .destini-css-1sl9a05,
      .destini-css-4i874u {
        height: 52px;
        width: 100%;
        padding: 0 17px;
        background-color: #EDF8E1;
        border-radius: 10px;
      }

      .destini-css-4i874u:hover,
      .destini-css-4i874u[data-hover] {
        background-color: #EDF8E1;
      }

      .destini-css-1sl9a05 {
        margin: 7px 0 14px;
        background: #000000;
      }

      .destini-css-1sl9a05:hover {
        background: #353535;
      }

      .destini-css-1sl9a05,
      .destini-css-1k4tjw3 {
        color: #ffffff;
      }

      .chakra-form-control input#searchZip,
      input[name="searchZip"] {
        font-size: 14px;
        line-height: 1.25;
        font-weight: 600;
        height: 50px;
        padding-left: 16px;
        padding-top: 30px;
        padding-right: 160px;
        padding-bottom: 12px;
        border: 1px solid #F0F0F0;
        border-radius: 50px;
      }

      @media (min-width: 375px) {
        .chakra-form-control input#searchZip,
        input[name="searchZip"] {
          height: 60px;
        }
      }

      .chakra-form-control .chakra-form__label {
        top: 12px;
        left: 18px;
        font-size: 12px;
        line-height: 1.2;
        font-weight: 600;
        letter-spacing: 0;
        color: #231F20;
        opacity: 0.6;
      }

      .destini-css-6l5g1r {
        width: auto;
        padding: 0;
      }

      #find-online-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
      }

      #find-online-list li {
        width: 100%;
        height: 160px;
        margin: 0;
      }

      #find-online-list li button {
        border-radius: 10px;
        line-height: 1.4;
        border-color: #ACACAC;
      }

      #find-online-list li button:hover {
        border-color: #231F20;
      }

      #find-online-list li button > div {
        flex-direction: column;
        justify-content: center;
        gap: 12px;
      }

      #find-online-list li button > div > div:first-child img {
        width: auto;
        height: 80px;
        object-fit: contain;
      }

      #find-online-list li button > div > div {
        flex-direction: column;
        flex: 0 1 auto;
      }

      .chakra-tabs__tablist button {
        font-size: 14px;
        line-height: 125%;
        font-weight: bold;
        border-color: #ACACAC;
        color: #ACACAC;
      }

      .chakra-tabs__tablist button[aria-selected="true"] {
        border-color: #FA4616;
        border-bottom-width: 2px;
        color: #000000;
      }

      #find-store-list li {
        padding: 26px 18px 24px 23px;
        border-radius: 10px;
      }

      div[role="region"][aria-label="location filter"] {
        padding: 21px 24px 21px 20px;
        margin: 0 0 25px;
        background: var(--background-color-filter);
      }

      div[role="region"] > div:first-child > div > button {
        height: auto;
      }

      div[role="region"] > div:first-child > div > button svg {
        width: 12px;
        height: 12px;
      }

      main > div {
        gap: 15px;
      }

      .destini-css-tvqnva:before,
      main > div > div > div > span > h2 {
        font-size: 24px !important;
        line-height: 1.25 !important;
        font-weight: 700 !important;
        display: block !important;
      }

      #popover-body button {
        color: #000000;
      }

      #find-store-list li > div > div > div:not(:first-child) span {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0px;
      }

      #find-store-list li > div:first-child > div {
        align-items: center;
        margin-bottom: 8px;
      }

      #find-store-list li > div > div:first-child span {
        padding: 5px 10px;
      }

      div[role="region"][aria-label="product filter"] div:nth-child(1) input {
        font-size: 14px;
        line-height: 1.25;
        font-weight: 600;
        height: 60px;
        padding-left: 16px;
        padding-top: 30px;
        padding-right: 160px;
        padding-bottom: 12px;
        border: 1px solid #F0F0F0;
        border-radius: 8px;
      }

      div[role="region"][aria-label="product filter"] div:nth-child(1) label {
        top: 12px;
        left: 16px;
        font-size: 12px;
        line-height: 1.2;
        font-weight: 600;
        letter-spacing: 0;
        color: #231F20;
        opacity: 0.6;
      }

      div[role="region"][aria-label="product filter"] div:nth-child(2) {
        margin-top: 15px;
      }

      footer {
        display: none !important;
        display: block;
      }

      @media (min-width: 768px) {
        #find-online-list {
          grid-template-columns: 1fr 1fr 1fr;
          gap: 10px;
          margin: 0;
        }

        #find-store-list li > div > div > div:not(:first-child) {
          align-items: flex-start;
          margin-top: -75px;
        }

        main > div > div > div {
          margin: 0 0 16px !important;
        }

        .destini-css-9ayuzj {
          grid-template-columns: repeat(3, 1fr);
          margin-right: 19px;
        }
      }

      .destini-css-g5e8j8 {
        padding-bottom: 70px;
      }

      @media (min-width: 375px) {
        .destini-css-y2ksjf {
          width: 123px;
        }
      }

      @media (min-width: 1024px) {
        .destini-css-1kb2g8x {
          padding: 23px 16px 5px;
        }

        .destini-css-14j0341 {
          top: 13px;
          left: 16px;
        }

        .destini-css-1u86yuw {
          margin: 38px 0 0 !important;
        }

        .destini-css-z9bkde {
          margin: 0 16px;
        }

        .destini-css-z9bkde button {
          margin: 0 6px;
        }

        div[role="region"][aria-label="location filter"] {
          align-items: center;
        }
      }

      @media (min-width: 1440px) {
        .destini-css-1khn86w + div {
          padding-left: 127px;
        }
      }

      .destini-css-v9g3sp:focus-visible,
      .destini-css-v9g3sp[data-focus-visible] {
        box-shadow: none;
      }
    `
    destiniRoot.shadowRoot.appendChild(style)
  }
}

window.customElements.define('store-locator', StoreLocator)