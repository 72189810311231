import { debounce } from 'lodash'

document.addEventListener("rebuy:smartcart.line-item-removed", (event) => {
  const cartInfo = event.detail.smartcart
  const item = event.detail.item

  if (item) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        items: [
          {
            item_id: item.sku,
            item_name: item.product_title,
            coupon: cartInfo.discount?.code || '',
            currency: window.BARREL && window.BARREL.currencyCode ? window.BARREL.currencyCode : 'USD',
            discount: (parseFloat(cartInfo.discount?.amount || 0) / 100).toFixed(2).toString(),
            index: 0,
            item_brand: item.vendor,
            item_category: '',
            item_list_id: "mini_cart",
            item_list_name: "Mini Cart",
            item_variant: item.variant_title,
            price: (parseFloat(item.price) / 100).toFixed(2).toString(),
            quantity: item.quantity
          }
        ]
      }
    })
  }
})

document.addEventListener("rebuy:smartcart.line-item-increase", (event) => {
  const cartInfo = event.detail.smartcart
  const item = event.detail.item
  const quantityNum = parseFloat(item.quantity) + 1

  if (item) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        items: [
          {
            item_id: item.sku,
            item_name: item.product_title,
            coupon: cartInfo.discount?.code || '',
            currency: window.BARREL && window.BARREL.currencyCode ? window.BARREL.currencyCode : 'USD',
            discount: cartInfo && cartInfo.discount ? (parseFloat(cartInfo.discount.amount) / 100).toFixed(2).toString() : '',
            index: 0,
            item_brand: item.vendor,
            item_category: '',
            item_list_id: "mini_cart",
            item_list_name: "Mini Cart",
            item_variant: item.variant_title,
            price: quantityNum > 1 ? (parseFloat(item.price) / 100 * quantityNum).toFixed(2).toString() : (parseFloat(item.price) / 100).toFixed(2).toString(),
            quantity: quantityNum
          }
        ]
      }
    })
  }
})

document.addEventListener("rebuy:smartcart.line-item-decrease", (event) => {
  const cartInfo = event.detail.smartcart
  const item = event.detail.item
  const quantityNum = parseFloat(item.quantity) - 1

  if (item) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        items: [
          {
            item_id: item.sku,
            item_name: item.product_title,
            coupon: cartInfo.discount?.code || '',
            currency: window.BARREL && window.BARREL.currencyCode ? window.BARREL.currencyCode : 'USD',
            discount: cartInfo && cartInfo.discount ? (parseFloat(cartInfo.discount.amount) / 100).toFixed(2).toString() : '',
            index: 0,
            item_brand: item.vendor,
            item_category: '',
            item_list_id: "mini_cart",
            item_list_name: "Mini Cart",
            item_variant: item.variant_title,
            price: quantityNum > 1 ? (parseFloat(item.price) / 100 * quantityNum).toFixed(2).toString() : (parseFloat(item.price) / 100).toFixed(2).toString(),
            quantity: quantityNum
          }
        ]
      }
    })
  }
})

document.addEventListener('rebuy.add', function(event){
  const item = event.detail.product

  if (item) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        items: [
          {
            item_id: item.id,
            item_name: item.title,
            currency: window.BARREL && window.BARREL.currencyCode ? window.BARREL.currencyCode : 'USD',
            index: 0,
            item_brand: item.vendor,
            item_category: '',
            item_list_id: "cart_upsell",
            item_list_name: "Cart Upsell",
            item_variant: item.variant_title,
            price: item.variants[0].price,
            quantity: item.quantity
          }
        ]
      }
    })
  }
})

const listEvents = ['rebuy:smartcart.ready', 'rebuy:cart.change']

listEvents.forEach(eventTarget => {
  document.addEventListener(eventTarget, debounce((event) => {
    const smartCartData = eventTarget == 'rebuy:smartcart.ready' ? event.detail.smartcart : event.detail.cart
    const smartcartEl = eventTarget == 'rebuy:smartcart.ready' ? smartCartData?.view.$el : document.querySelector('#rebuy-cart')
    const buttonSubmitEls = smartcartEl?.querySelectorAll('.js-button-checkout')

    updateGiftMessageAttributes(smartCartData, smartcartEl, buttonSubmitEls)
  }, 500))
})

function updateGiftMessageAttributes (smartCartData, smartcartEl, buttonSubmitEls) {
  if (smartCartData.cart.note) {
    updateAttributes(smartCartData.cart.note)
  }

  buttonSubmitEls.forEach(button => {
    button?.addEventListener('click', debounce((event) => {
      const smartcartNote = smartcartEl?.querySelector('.js-notes')
      updateAttributes(smartcartNote.value)
    }, 500))
  })


  function updateAttributes (value) {
    fetch('/cart/update.js', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        attributes: {
          GiftMessage: value
        }
      })
    })
    .then(response => {
      if (response.ok) {
        return response.json()
      }
      throw new Error('Network response was not ok.')
    })
    .catch(error => {
      console.error('There was a problem updating item attributes:', error)
    })
  }
}