import { handleize } from './utils'
import { getProductData, pushSelectProductItem, scrollHandle, observerProductCards } from './datalayer'

document.addEventListener('DOMContentLoaded', () => {
  const btnSubmitEls = document.querySelectorAll('[data-submit-search]')
  if (btnSubmitEls && btnSubmitEls.length > 0) {
    btnSubmitEls.forEach(btn => {
      const formEl = btn.closest('[data-form-submit]')
      btn.addEventListener('click', (e) => {
        e.preventDefault()
        addValueHide(formEl)
      })

      formEl.addEventListener('submit', (e) => {
        e.preventDefault()
        addValueHide(formEl)
      })
    })
  }
})

const addValueHide = (el) => {
  const inputEl = el.querySelector('[data-search-input]')
  window.location.href = `/search?q=${inputEl.value} -tag:_hidden_categories&options[prefix]=last`
}

// Datalayer > Select Item
document.addEventListener('click', (e) => {
  const productEl = e.target.closest('.js-product-item')
  if (e.target.tagName === 'A' && productEl) {
    const { datalayerIndex, datalayerPlacement, datalayerProduct } = productEl.dataset
    const data = JSON.parse(decodeURIComponent(datalayerProduct))
    data.item_list_id = handleize(datalayerPlacement)
    data.item_list_name = datalayerPlacement
    data.index = datalayerIndex
    pushSelectProductItem(getProductData(data))
  }
})

// Datalayer > View Item List

const productCards = document.querySelectorAll('.js-product-item')
if (productCards) {
  observerProductCards(productCards)
}

document.addEventListener('scroll', () => {
  scrollHandle()
})

const carouselEls = document.querySelectorAll('.swiper-wrapper, .featured-collection-slider__products')
carouselEls.forEach(carousel => {

  const config = { attributes: true, childList: true, subtree: true }
  const callback = (mutationList, observer) => {
    for (const mutation of mutationList) {
      if (mutation.type === "attributes" && carousel.querySelector('.js-product-item')) {
        scrollHandle()
      }
    }
  }

  const observer = new MutationObserver(callback)
  observer.observe(carousel, config)
})
