const main = document.querySelector('main')
const h1 = document.querySelector('main h1')

if (!h1 && main) {
  let newH1 = document.createElement('h1')
  const title = document.createTextNode(document.title)
  newH1.appendChild(title)
  newH1.contentText = document.title
  newH1.classList.add('sr-only')
  main.insertBefore(newH1, main.firstChild)
}
