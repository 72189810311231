import Swiper, { Scrollbar } from 'swiper'

class GridSecondary extends HTMLElement {
  constructor() {
    super()
    this.swiper = Swiper
    this.init = false

    this.swiperEl = this.querySelector('.js-swiper')
    this.swiper = null
    if (this.swiperEl) {
      this.initSwiper()

      window.addEventListener('resize', () => {
        this.initSwiper()
      })
    }
  }

  initSwiper () {
    let mobile = window.matchMedia('(min-width: 0px) and (max-width: 767px)');
    let tablet = window.matchMedia('(min-width: 768px)');

    if (mobile.matches) {
      if(!this.init) {
        this.init = true

        const options = {
          slidesPerView: 'auto',
          spaceBetween: 10,
          modules: [Scrollbar],
          draggable: true,
          scrollbar: {
            dragSize: 73,
            el: '.swiper-scrollbar',
            hide: false,
            draggable: true,
          },
        }
        this.swiper = new Swiper(this.swiperEl, options)
      }
    } else if (tablet.matches) {
      this.swiper && this.swiper.destroy()
      this.init = false
    }
  }
}

window.customElements.define('grid-secondary', GridSecondary)
