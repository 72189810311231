document.addEventListener('DOMContentLoaded', () => {
  const bodyScrollLock = () => {
    document.body.style.overflow = 'hidden'
  }
  const klaviyoButtonTrigger = () => {
    const btnTrigers = document.querySelector('.js-pdp-add-to-cart')
    const btnKlaviyoTrigers = document.querySelectorAll('.klaviyo-bis-trigger')
    if (btnTrigers) {
      btnTrigers.addEventListener('click', () => {
        const btn = document.querySelector('.js-pdp-add-to-cart.klaviyo-bis-trigger') || null
        const klaviyoEl = document.querySelector('#klaviyo-bis-iframe')
        if (klaviyoEl.style.display != '' && btn) {
          bodyScrollLock()
        }
      })
    }

    if (btnKlaviyoTrigers && btnKlaviyoTrigers.length > 0) {
      btnKlaviyoTrigers.forEach(btn => {
        btn.addEventListener('click', () => {
          const klaviyoEl = document.querySelector('#klaviyo-bis-iframe')
          if (klaviyoEl && klaviyoEl.style.display != '') {
            bodyScrollLock()
          }
        })
      })
    }
  }


  const klaviyoModalTrigger = () => {
    const klaviyoEl = document.querySelector('#klaviyo-bis-iframe')
    if (klaviyoEl.style.display == 'none') {
      document.body.style.overflow = null
    }
  }

  const callback = (mutationList, observer) => {
    for (const mutation of mutationList) {
      if (mutation.type === "attributes") {
        klaviyoModalTrigger()
      }
    }
  }

  const observer = new MutationObserver(callback)

  setTimeout(() => {
    const klaviyoEl = document.querySelector('#klaviyo-bis-iframe')
    if(klaviyoEl) {
      klaviyoButtonTrigger()
      observer.observe(klaviyoEl, { attributes: true, childList: false, subtree: false })
    }
  }, 3000)
})
