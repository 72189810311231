import { findElementAsync } from './utils'

class RechargeWidgetsCustom extends HTMLElement {
  constructor () {
    super ()

    this.subscribePanel = this.querySelector('.js-subscribe')
    this.subscribeHeading = this.subscribePanel.querySelector('.js-heading')
    this.oneTimePanel = this.querySelector('.js-one-time')
    this.oneTimeHeading = this.oneTimePanel.querySelector('.js-heading')

    this.cloneChipButtons = this.querySelectorAll('.js-button-clone-chip')
    this.chipButtons = this.querySelectorAll('.js-button-chip')
    this.variants = JSON.parse(document.querySelector('[data-variant-json]').innerText)
    this.productPriceEl = document.querySelectorAll('.js-product-price')
    this.chipButtonEls = document.querySelectorAll('.js-button-chip')
    this.stickyAtcCardPrice = document.querySelector('.js-sticky-atc-card-price')
    this.stickyAtcCardComparePrice = document.querySelector('.js-sticky-atc-card-compare-price')
    this.addToCartEl = document.querySelector('.js-pdp-add-to-cart [data-add-to-cart-rebuy-text]')
    this.productForm = document.querySelector('product-main product-form')
    this.saveSubscription = false

    this.cloneOptions = this.querySelectorAll('.js-option')

    this.cloneChipButtons && this.cloneChipButtons.forEach(button => {
      this.setActiveVariant(button)
    })

    this.chipButtonEls && this.chipButtonEls.forEach(button => {
      this.setActiveVariant(button)
    })

    this.cloneChipButtons && this.initStatus()

    this.initialize()
  }

  async initialize() {
    try {
      const selectors = await Promise.all([
        findElementAsync('.rc-option__subsave'),
        findElementAsync('.rc-option__onetime'),
        findElementAsync('.rc_widget__option__plans__dropdown.rc-selling-plans__dropdown')
      ])
      this.rechargeWidgetSubsave = selectors[0]
      this.rechargeWidgetOneTime = selectors[1]
      this.rechargeDropdown = selectors[2]

      if (this.rechargeDropdown || this.rechargeWidgetOneTime || this.rechargeWidgetSubsave) {
        this.handleRechargeTypeSelect()
        this.classList.add('active')
        this.changeData()
        if (this.rechargeDropdown && this.cloneOptions.length) {
          this.cloneOptions.forEach(option => {
            this.handleSelectRechargeOption(option)
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  handleRechargeTypeSelect() {
    const setSubSaveActive = () => {
      this.clearPanelStatus()
      this.subscribePanel.classList.add('open')
      this.subscribeHeading.classList.add('selected')
      this.saveSubscription = true
    }

    const setOneTimeActive = () => {
      this.clearPanelStatus()
      this.oneTimePanel.classList.add('open')
      this.oneTimeHeading.classList.add('selected')
      this.saveSubscription = false
    }

    if (this.rechargeWidgetSubsave.classList.contains('rc-option--active')) {
      setSubSaveActive()
    } else if (this.rechargeWidgetOneTime.classList.contains('rc-option--active')) {
      setOneTimeActive()
    }

    this.oneTimePanel.addEventListener('click', (event) => {
      const contentEl = event.target.closest('.js-subsave-content')
      if (!contentEl) {
        setOneTimeActive()
        this.rechargeWidgetOneTime.click()
        this.changeData()
      }
    })

    this.subscribePanel.addEventListener('click', (event) => {
      const contentEl = event.target.closest('.js-subsave-content')
      if (!contentEl) {
        setSubSaveActive()
        this.rechargeWidgetSubsave.click()
        this.changeData()
      }
    })
  }

  clearPanelStatus () {
    this.subscribePanel.classList.remove('open')
    this.subscribeHeading.classList.remove('selected')
    this.oneTimePanel.classList.remove('open')
    this.oneTimeHeading.classList.remove('selected')
  }

  setActiveVariant(button) {
    button.addEventListener('click', () => {
      const value = button.dataset.optionValue
      const buttonMatchValue = Array.from(this.chipButtons)?.find(button => button.dataset.optionValue === value)
      const buttonsCloneMatchValue = Array.from(this.cloneChipButtons)?.filter(button => button.dataset.optionValue === value)
      if (buttonMatchValue) {
        this.clearSelectVariant()
        buttonMatchValue.click()
        buttonsCloneMatchValue?.forEach(button => {
          button.classList.add('selected')
        })
      }
      this.changeData()
    })
  }

  handleSelectRechargeOption (option) {
    option.addEventListener('click', () => {
      const value = option.dataset.value
      this.rechargeDropdown.value = value
      this.clearSelectOption()
      option.classList.add('selected')
    })
  }

  clearSelectVariant () {
    this.cloneChipButtons.forEach(button => {
      button.classList.remove('selected')
    })
  }

  clearSelectOption () {
    this.cloneOptions.forEach(option => {
      option.classList.remove('selected')
    })
  }

  changeData () {
    setTimeout(() => {
      const rechargePrice = document.querySelector('[data-price-mutation-watcher]')
      if (rechargePrice) {
        const comparePrice = rechargePrice.querySelector('[data-product-compare-price]')
        const price = rechargePrice.querySelector('.recharge-inner-most-price')
        const priceTmp = rechargePrice.querySelector('[data-product-money]')
        const variantSelect = this.variants.find(variant => variant.id === window.ReChargeWidget.currentVariantId)

        if (variantSelect) {
          if (variantSelect.compare_at_price) {
            comparePrice.classList.remove('hidden')
            comparePrice.textContent = window.Shopify.formatMoney(variantSelect.compare_at_price)
          } else {
            comparePrice.classList.add('hidden')
          }
        }

        if (variantSelect && this.saveSubscription) {
          priceTmp.classList.remove('hidden')
          price.classList.add('hidden')
          priceTmp.textContent = window.Shopify.formatMoney(variantSelect.selling_plan_allocations[0].price)
          this.changePrice(window.Shopify.formatMoney(variantSelect.compare_at_price), window.Shopify.formatMoney(variantSelect.selling_plan_allocations[0].price))
        } else {
          priceTmp.classList.add('hidden')
          price.classList.remove('hidden')
          this.changePrice(window.Shopify.formatMoney(variantSelect.compare_at_price), price.innerText)
        }
      }
    }, 500)
  }

  changePrice (comparePrice, price) {
    if (this.stickyAtcCardPrice && price != '') this.stickyAtcCardPrice.innerText = price
    if (this.stickyAtcCardComparePrice && comparePrice != '' && comparePrice != '$0') this.stickyAtcCardComparePrice.innerText = comparePrice
    if (this.addToCartEl && price != '') this.addToCartEl.innerText = this.addToCartEl.innerText.replace(/\$\d+\.\d{2}/, price)
    if (this.productForm && this.productForm.dataset.priceProduct) this.productForm.dataset.priceProduct = price.replace('$', '')
  }

  initStatus () {
    this.chipButtons.forEach((button, index) => {
      if (button.classList.contains('disabled')) {
        this.cloneChipButtons[index].classList.add('disabled')
        if (this.cloneChipButtons.length / this.chipButtons.length === 2) {
          this.cloneChipButtons[index + this.chipButtons.length].classList.add('disabled')
        }
      }
    })
  }
}

window.customElements.define('recharge-widgets-custom', RechargeWidgetsCustom)