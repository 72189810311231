const ANCHOR_SIDEBAR_SELECTOR = '.js-anchor-sidebar'
const LIST_SECTION = '.js-list-section'
const ANCHOR_SELECTOR = '.js-anchor'
const ANCHOR_FIRST_CLASS = 'anchored-first'
const ACTIVE_CLASS = 'is-active'
const WYSIWYG_CONTENT_SELECTOR = '.js-wysiwyg-content'

class ArticleMain extends HTMLElement {
  constructor () {
    super()

    this.anchorEls = this.querySelectorAll('h2')
    this.anchorSidebarEl = this.querySelector(ANCHOR_SIDEBAR_SELECTOR)
    this.listSection = this.querySelector(LIST_SECTION)
    const headerEl = document.querySelector('header')
    this.headerHeight = ((headerEl && headerEl.offsetHeight) || 0)

		if (this.anchorEls && this.anchorEls.length) {
      this.anchorSidebarEl.classList.remove('hidden')
      this.handlePositionSection(this.anchorEls)
    }
  }

  handlePositionSection (sections) {
    sections.forEach((section, index) => {
      section.setAttribute('id', `anchor-${index}`)
    })
    this.initAnchor()
  }

	initAnchor() {
		const sections = this.querySelectorAll('h2')

    sections.forEach((el, index) => {
      if (el.id != '') {
        const itemEl = document.createElement('li')
        itemEl.className = 'p1 leading-none'
        let aClass = "js-anchor p1 article__left-content-link underline font-normal transition-all"
        const innerText = new DOMParser().parseFromString(el.innerHTML, 'text/html').body.innerText
        itemEl.innerHTML = `<a href="#${el.id}" arial-label="Anchor Navigation" role="button" class="${aClass}">${innerText}</a>`
				this.listSection && this.listSection.appendChild(itemEl)
      }
    })
    this.selectAnchor()
  }

	selectAnchor() {
    const anchorEls = this.querySelectorAll(ANCHOR_SELECTOR)
    if (anchorEls.length > 0) {
      anchorEls.forEach((el) => {
        el.addEventListener('click', (event) => {
					event.preventDefault()
          this.initActive(anchorEls)
          this.scrollToSection(el)
          el.classList.add(ACTIVE_CLASS)
        })
      })
    }
  }

	initActive(els) {
    els.forEach(el => {
      el.classList.remove(ACTIVE_CLASS)
      if (el.classList.contains(ANCHOR_FIRST_CLASS)) {
        el.classList.remove(ANCHOR_FIRST_CLASS)
      }
    })
  }

  scrollToSection (aEl) {
    const section = document.querySelector(aEl.hash)
    const fromTop = window.scrollY
    const sectionOffsetTop = section.getBoundingClientRect().top + fromTop
    const offsetTop = this.headerHeight
    const top = sectionOffsetTop - offsetTop - 20

		window.scrollTo({
			top: top,
			behavior: 'smooth'
		})
  }
}

customElements.define('article-main', ArticleMain)
