import Swiper, { Scrollbar, Mousewheel } from 'swiper'

class BlogPosts extends HTMLElement {
  constructor() {
    super()

    this.swiperEl = this.querySelector('.js-swiper')
    this.swiper = null
    this.swiperEl && this.initSwiper()
  }

  initSwiper () {
    const options = {
      slidesPerView: 'auto',
      spaceBetween: 10,
      modules: [Scrollbar, Mousewheel],
      mousewheel: {
        forceToAxis: true
      },
      grabCursor: true,
      scrollbar: {
        dragSize: 73,
        el: '.swiper-scrollbar',
        hide: false,
        draggable: true,
      },
      breakpoints: {
        320: {
          spaceBetween: 10
        },
        960: {
          spaceBetween: 20
        }
      }
    }
    this.swiper = new Swiper(this.swiperEl, options)
  }
}

window.customElements.define('blog-posts', BlogPosts)