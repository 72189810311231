import { decode } from '../utils'

class CartItemRemove extends HTMLElement {
  constructor () {
    super()

    this.addDatalayer = this.addDatalayer.bind(this)


    this.addEventListener('click', (event) => {
      event.preventDefault()
      this.addDatalayer()
    })
  }

  addDatalayer () {
    const item = this.closest('cart-item')
    const itemData = JSON.parse(decode(item.dataset.item))
    const itemCategories = JSON.parse(decode(item.getAttribute('data-categories')))
    const itemCategoriesString = itemCategories.toString().replace('[', '').replace(']', '')
    const itemListName = item.getAttribute('data-item-list')
    const itemListId = itemListName.toLocaleLowerCase().replace(' ', '_')


    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        items: [
          {
            item_id: itemData.sku,
            item_name: itemData.product_title,
            coupon: itemData.discount && itemData.discount.code ? itemData.discount.code : '',
            currency: window.BARREL && window.BARREL.currencyCode ? window.BARREL.currencyCode : 'USD',
            discount: itemData.discount && itemData.discount.amount ? (parseFloat(itemData.discount.amount) / 100).toFixed(2).toString() : '',
            index: 0,
            item_brand: itemData.vendor,
            item_category: itemCategoriesString,
            item_variant: item.variant_title,
            item_list_id: itemListId,
            item_list_name: itemListName,
            price: (parseFloat(itemData.price) / 100).toFixed(2).toString(),
            quantity: itemData.quantity
          }
        ]
      }
    })
  }
}

customElements.define('cart-item-remove', CartItemRemove)

class CartQuantity extends HTMLElement {
  constructor () {
    super()

    this.buttonAdd = this.querySelector('[data-add-quantity]')
    this.buttonSubtract = this.querySelector('[data-subtract-quantity]')

    this.buttonSubtract.addEventListener('click', (event) => {
      event.preventDefault()
      this.addDatalayer('remove_from_cart')
    })

    this.buttonAdd.addEventListener('click', (event) => {
      event.preventDefault()
      this.addDatalayer('add_to_cart')
    })
  }

  addDatalayer (eventName) {
    const item = this.closest('cart-item')
    const itemData = JSON.parse(decode(item.dataset.item))
    const itemCategories = JSON.parse(decode(item.getAttribute('data-categories')))
    const itemCategoriesString = itemCategories.toString().replace('[', '').replace(']', '')
    const itemListName = item.getAttribute('data-item-list')
    const itemListId = itemListName.toLocaleLowerCase().replace(' ', '_')

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: eventName,
      ecommerce: {
        items: [
          {
            item_id: itemData.sku,
            item_name: itemData.product_title,
            coupon: itemData.discount && itemData.discount.code ? itemData.discount.code : '',
            currency: window.BARREL && window.BARREL.currencyCode ? window.BARREL.currencyCode : 'USD',
            discount: itemData.discount && itemData.discount.amount ? (parseFloat(itemData.discount.amount) / 100).toFixed(2).toString() : '',
            index: 0,
            item_brand: itemData.vendor,
            item_category: itemCategoriesString,
            item_variant: item.variant_title,
            item_list_id: itemListId,
            item_list_name: itemListName,
            price: (parseFloat(itemData.price) / 100).toFixed(2).toString(),
            quantity: itemData.quantity
          }
        ]
      }
    })
  }
}

customElements.define('quantity-input', CartQuantity)
