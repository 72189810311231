class SubscriptionBuyBox extends HTMLElement {
  constructor() {
    super()
    this.listSubscriptionProductEls = this.querySelectorAll('.js-subscription-product-item')
    this.productSelectorEls = this.querySelectorAll('.js-select-product')

    this.listSubscriptionProductEls.forEach(productEl => {
      const productSelector = productEl.querySelector('.js-select-product')
      const productPriceInnerELs = productEl.querySelectorAll('.js-product-price-inner')
      const dataPriceEl = productEl.querySelector('[data-price]')
      const dataPriceSellingPlanEls = productEl.querySelectorAll('[data-price-selling-plan]')
      const selectorSellingPlanEl = productEl.querySelector('.js-select-selling-plan')
      const buttonSubmitEl = productEl.querySelector('.js-product-form-submit ')
      const buttonTextEl = buttonSubmitEl?.querySelector('[data-add-to-cart-text]')

      productSelector?.addEventListener('change', (event) => {
        this.clearAllSate()
        this.showProductBox(event)
      })

      if (productPriceInnerELs && selectorSellingPlanEl && dataPriceEl) {
        const updateSellingPrice = () => {
          const selectedOption = selectorSellingPlanEl.options[selectorSellingPlanEl.selectedIndex]
          const currentSymbol = productPriceInnerELs[0].getAttribute('data-currency-symbol')
          const priceContent = dataPriceEl.innerHTML
          const price = priceContent?.replace(currentSymbol, '')
          const priceAdjustment = selectedOption?.getAttribute('data-price-adjustments')
          const newPrice = price && priceAdjustment ? parseFloat(parseFloat(price) * (100 - parseFloat(priceAdjustment)) / 100).toFixed(2) : 0
          if (dataPriceSellingPlanEls && newPrice) {
            dataPriceSellingPlanEls.forEach(el => el.innerHTML = currentSymbol + newPrice)
            productPriceInnerELs.forEach(el => el.classList.add('show-price-selling-plan'))
            if (buttonTextEl) {
              const langAvailable = buttonSubmitEl.getAttribute('data-lang-available')
              buttonTextEl.textContent = langAvailable + ' - ' + currentSymbol + newPrice;
            }
          } else {
            productPriceInnerELs.forEach(el => el.classList.remove('show-price-selling-plan'))
          }
        }
        updateSellingPrice()
        selectorSellingPlanEl.addEventListener('change', () => {
          updateSellingPrice()
        })
        document.addEventListener('change-price', () => {
          updateSellingPrice()
        })
      }
    })
  }

  showProductBox (event) {
    const value = event.target.value
    const productActive = Array.prototype.slice.call(this.listSubscriptionProductEls).find((product) => product.dataset.handle === value)
    if (productActive) {
      productActive.classList.add('active')
    }

    if (this.productSelectorEls) {
      this.productSelectorEls.forEach(el => el.value = value)
    }
  }

  clearAllSate () {
    this.listSubscriptionProductEls.forEach(productEl => {
      productEl.classList.remove('active')
    })
  }
}

customElements.define('subscription-buy-box', SubscriptionBuyBox)