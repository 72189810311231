import { pushFormSubmit } from './datalayer'
class Newsletter extends HTMLElement {
  constructor () {
    super ()
    this.input = this.querySelector('.js-input')

    this.formElement = this.querySelector('form')
    this.emailField = this.querySelector('input[type="email"]')
    this.successMessage = this.querySelector('.js-success-message')
    this.errorMessageEls = Array.prototype.slice.call(this.querySelectorAll('.js-error-message'))
    this.klaviyoListId = this.dataset.listId

    this.input?.addEventListener('input', () => {
      this.input.scrollWidth > this.input.clientWidth ? this.classList.add('over-content') : this.classList.remove('over-content')
    });

    if (this.formElement) {
      this.formElement.addEventListener('submit', this.onFormSubmit.bind(this))

      // Datalayer > Push form_submission
      this.formElement.addEventListener('submit', () => {
        const formName = this.formElement.getAttribute('id')
        localStorage.setItem('formName', formName)
      })
    }
  }

  parseJSON (string) {
    let json = {}
    string = string.replace(/<!--.*-->/g, '')
    try {
      json = JSON.parse(string)
    } catch (e) {
      console.log(e)
    }
    return json
  }

  async onFormSubmit (event) {
    event.preventDefault()

    if (!this.emailField) {
      return
    }

    this.query = {
      email: this.emailField.value,
      g: this.klaviyoListId
    }

    const isValid = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const checkValid = isValid.test(this.emailField.value)

    if (!checkValid) {
      event.preventDefault()
      this.classList.add('form-error')
      this.errorMessageEls.forEach(errorMessageEl => {
        errorMessageEl.toggleAttribute('hidden', true)
      })
      this.showError('Please enter a valid email address.')

      return
    }

    const query = Object.keys(this.query).map(k => {
      let value = this.query[k]
      return `${k}=${value}`
    }).join('&')

    fetch('https://manage.kmail-lists.com/ajax/subscriptions/subscribe', {
      method: 'POST',
      body: query,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "cache-control": "no-cache"
      },
    })
      .then((response) => response.text())
      .then((responseText) => {
        const parsed = this.parseJSON(responseText)
        if (parsed && parsed['success']) {
          this.showSuccess(true)
          this.showError('')
          const formName = localStorage.getItem('formName')
          if (formName) {
            pushFormSubmit(formName)
            localStorage.setItem('formName', '')
          }
        } else {
          this.showError('Whoops, something went wrong')
          console.error('newsletter', parsed)
        }
      })
  }

  showSuccess (isSuccessful) {
    if (this.successMessage) {
      this.successMessage.toggleAttribute('hidden', true)
    }

    if (isSuccessful && this.formElement) {
      this.formElement.classList.add('hidden')
    }
  }

  showError (isError) {
    if (isError) {
      this.showSuccess(false)
    }

    if (this.errorMessageEls) {
      this.errorMessageEls.forEach(errorMessageEl => {
        errorMessageEl.toggleAttribute('hidden', false)
        errorMessageEl.innerHTML = isError
      })
    }
  }
}

window.customElements.define('newsletter-form', Newsletter)
