class FilterSelect extends HTMLElement {
  constructor () {
    super ()

    this.select = this.querySelector('select')
    this.links = this.querySelectorAll('button')

    if (this.select) {
      this.select.addEventListener('change', () => {
        const urlParams = new URLSearchParams(window.location.search)
        const url = this.select.value + '?' + urlParams.toString()
        window.location.replace(urlParams.size ? url : this.select.value)
      })
    }

    if (this.links) {
      this.links.forEach(link => {
        link.addEventListener('click', () => {
          const href = link.getAttribute('data-href')
          const urlParams = new URLSearchParams(window.location.search)
          const url = href + '?' + urlParams.toString()
          window.location.replace(urlParams.size ? url : href)
        })
      })
    }
  }
}

window.customElements.define('filter-select', FilterSelect)