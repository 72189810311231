import { handleAddToCartClick } from '../datalayer.js'

class ProductFormElement extends HTMLElement {
  constructor () {
    super()

    if (this.dataset.variants) {
      this.variants = JSON.parse(this.dataset.variants)
    }

    const formEl = this.querySelector('form')
    const { parameter, datalayerProduct } = this.dataset

    if (formEl) {
      const productData = JSON.parse(decodeURIComponent(datalayerProduct))
      productData.index = 0
      productData.item_list_id = 'product_form'
      productData.item_list_name = 'Product Form'

      formEl.addEventListener('submit', (event) => {
        event.preventDefault()
        const priceProduct = this.dataset.priceProduct
        const handleVariantAvailablePrice = priceProduct ? parseFloat(priceProduct) * 100 : ''
        const variantAvailable = productData.variants.find(variant => variant.available)

        if (priceProduct && priceProduct !== productData.price) {
          productData.price = priceProduct

          if (variantAvailable.length > 1) {
            Array.prototype.slice.call(variantAvailable).forEach(variant => {
              variant.price = handleVariantAvailablePrice
            });
          } else {
            variantAvailable.price = handleVariantAvailablePrice
          }
        }

        handleAddToCartClick(productData, productData.index, parameter, variantAvailable)
      })
    }
  }
}

customElements.define('product-form', ProductFormElement)
