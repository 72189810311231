class AccountLogin extends HTMLElement {
  constructor() {
    super()
    this.accountLoginEl = document.querySelector('account-login')

    if (this.accountLoginEl) {
      this.formEls = Array.prototype.slice.call(this.querySelectorAll('form'))
      if (this.formEls && this.formEls.length > 0) {
        this.formEls.map(el => {
          const inputEls = el.querySelectorAll('input:not([type=hidden])')
          const buttonSubmitEl = el.querySelector('button[type="submit"]')
          buttonSubmitEl && buttonSubmitEl.addEventListener('click', (event) => {
            event.preventDefault()
            this.validateForm(inputEls, el)
          })
        })
      }
    }
  }

  showError(input) {
    const formControl = input.closest('text-input')
    const error = formControl.querySelector('.js-text-error')
    input.classList.add('input-error')
    error.classList.remove('hidden')
  }

  showSuccess(input) {
    const formControl = input.closest('text-input')
    const error = formControl.querySelector('.js-text-error')
    input.classList.remove('input-error')
    error.classList.add('hidden')
  }

  validateForm(inputArr, form) {
    let check = true
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    inputArr.forEach((input) => {
      if (input.value.trim() === '') {
        check = false
        this.showError(input)
      } else {
        this.showSuccess(input)
        if (input.type.toLowerCase() === 'email' ) {
          if(re.test(input.value.trim())) {
            this.showSuccess(input)
          }else {
            check = false
            this.showError(input)
          }
        }
      }
    })
    if (check) {
      form.submit()
    }
  }
}

customElements.define('account-login', AccountLogin)