class BackInStockForm extends HTMLElement {
  constructor() {
    super()

    this.modalProductTitle = this.querySelector('.js-product-title')
    this.modalProductId = this.querySelector('.js-product-id')
    this.modalSelectVariants = this.querySelector('.js-select')
    this.buttonClose = this.querySelector('.js-close')
    this.apiKey = this.dataset.apiKey
    this.errorMessage = this.querySelector('.js-error-message')

    this.form = this.querySelector('form')

    this.addEventListener('click', (event) => {
      const buttonClose = event.target.closest('.js-close')
      const innerContent = event.target.closest('.js-inner-content')
      if (!innerContent || buttonClose) {
        this.clearAllData()
      }
    })

    this.form.addEventListener('submit', (event) => {
      event.preventDefault()
      this.handleFormData(event)
    })
  }

  handleFormData(event) {
    this.form.classList.remove('error-form')
    this.form.classList.remove('success-form')

    const emailInput = this.form.querySelector("input[type=email]");
    const emailValue = emailInput.value;

    if (emailValue === '') {
      this.errorMessage.innerHTML = "Please enter an email address."
      this.form.classList.add('error-form')
      return;
    }

    if (!this.validateEmail(emailValue)) {
      this.errorMessage.innerHTML = "Please enter a valid email"
      this.form.classList.add('error-form')
      return;
    }

    var formData = new FormData(event.target);

    fetch("https://a.klaviyo.com/onsite/components/back-in-stock/subscribe", {
      method: "POST",
      body: formData
    })
    .then(function(response) {
      if (response.ok) {
        return response.text();
      } else {
        throw new Error("An error occurred.");
      }
    })
    .then(function(data) {
      event.target.classList.add('success-form')
      const selectEl = event.target.querySelector('.js-select')
      const inputEl = event.target.querySelector('input[type="email"]')

      selectEl && selectEl.setAttribute('disabled', 'disabled')
      inputEl && inputEl.setAttribute('disabled', 'disabled')
    })
    .catch(function(error) {
      console.error(error);
    });
  }

  clearAllData() {
    this.classList.remove('active')
    document.body.classList.remove('overflow-hidden')

    if (this.modalProductTitle) {
      this.modalProductTitle.innerHTML = 'Product'
    }

    if (this.modalProductId) {
      this.modalProductId.value = ''
    }

    if (this.modalSelectVariants) {
      this.modalSelectVariants.innerHTML = ''
    }
  }

  validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
}

customElements.define('back-in-stock-form', BackInStockForm)