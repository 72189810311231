class ArticleLandingNavigation extends HTMLElement {
  constructor() {
    super()
    this.headerEl = document.querySelector('.js-site-header')
    this.navEl = this.querySelector('.js-article-landing-nav')

    if (this.navEl) {
      this.init()
      window.addEventListener('resize', () => this.init())
    }
  }

  init () {
    const offset = this.headerEl ? this.headerEl.offsetHeight : 0
    this.navEl.setAttribute('style', `top: ${offset}px`)
  }
}

window.customElements.define('article-landing-navigation', ArticleLandingNavigation)
