class BackInStockButton extends HTMLElement {
  constructor() {
    super()
    this.button = this.querySelector('.js-button')
    this.productInfoEl = this.querySelector('.js-product')
    this.productId = this.productInfoEl && this.productInfoEl.dataset.id
    this.productTitle = this.productInfoEl && this.productInfoEl.dataset.title
    this.productVariantEls = this.querySelectorAll('.js-variant-item')

    this.modal = document.querySelector('back-in-stock-form')
    this.modalProductTitle = this.modal?.querySelector('.js-product-title')
    this.modalProductId = this.modal?.querySelector('.js-product-id')
    this.modalSelectVariants = this.modal?.querySelector('.js-select')

    this.button.addEventListener('click', () => {
      this.modal.classList.add('active')
      document.body.classList.add('overflow-hidden')

      if (this.modalProductTitle) {
        this.modalProductTitle.innerHTML = this.productTitle
      }

      if (this.modalProductId) {
        this.modalProductId.value = this.productId
      }

      if (this.productVariantEls.length > 0 && this.modalSelectVariants) {
        this.productVariantEls.forEach(variant => {
          const value = variant.dataset.value
          const title = variant.dataset.title

          if (value && title) {
            const option = document.createElement('option')
            option.text = title
            option.value = value
            this.modalSelectVariants.add(option)
          }
        })
      }
    })
  }
}

customElements.define('back-in-stock-button', BackInStockButton)